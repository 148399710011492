import DeviceConstructor from './device_constructor';
import DeviceUtils from './device_utils';
import '@splidejs/splide/css';
import Splide from '@splidejs/splide';

class OrderDevice {
  #device; // It's updated when user clicks on Order button for specific device
  #account; // Account info from server side
  #types; // Available devices types
  #codeboxTypes; // Digital lockboxes
  #insurancePercentage; // Percentage added to the total amount, when insurance option is selected
  #scaledPricing; // Additional info for price calculation
  #wrapper; // Dom container for modal window with shipping form and confirm button OR wrapper for form on Account Setup page
  #sameKeyAdditionalFee; // Additional info for price calculation
  #selectedDeviceField; // Field to store selected device when shipping modal window is opened
  #devicesCarousels = []; // Variable to store all instances of devices carousels

  getDevicePrice(device, lockboxType, value) {
    let price = 0;

    if (this.#account.codebox_scaled_pricing) {
      price = this.#scaledPricing.filter(function(x) {
        return x.from <= value && value <= x.to;
      })[0].price[lockboxType];
    } else {
      price = device.getPrice();
    }
    return price;
  }

  // Calculates price for selected device, update html in shipping modal for single device price and total price for selected devices amount
  setTotalAmountText(withAmountPrefix = false) {
    const totalAmountPrefix = withAmountPrefix ? 'Your credit card will be charged ' : '';
    const deviceCount = this.#wrapper.querySelector('.js-devices-count-field').value;
    const lockboxType = this.getSelectedDeviceType();
    const insuranceAmountLabel = this.#wrapper.querySelector('.js-insurance-price');
    const insuranceEnabled = this.insuranceOptionCheckbox.checked;
    let price;
    const priceWrapper = this.#wrapper.querySelector('.codebox.count strong');
    if (deviceCount >= 0 && lockboxType) {
      price = this.getDevicePrice(this.#device, lockboxType, deviceCount);
      this.#device.printFormattedPrice();

      var same_key_enabled = this.#wrapper.querySelector('input.same_key_enabled').checked;
      var sameKeyFee = (lockboxType == this.#types.TYPE_VAULT && same_key_enabled) ? this.#sameKeyAdditionalFee : 0;
      var amount = deviceCount * (price + sameKeyFee);
      price = price || 0;
      amount = amount || 0;
      const insuranceAmount = +(Math.round(amount * this.#insurancePercentage / 100 + 'e+2')  + 'e-2');
      const addedInsuranceAmount = insuranceEnabled ? insuranceAmount : 0;
      if (priceWrapper) {
        priceWrapper.innerHTML = '$' + price;
      }
      insuranceAmountLabel.innerHTML = insuranceAmount > 0 ? `$${insuranceAmount}` : '';
      this.#wrapper.querySelector('#total_amount_text').innerHTML = `${totalAmountPrefix}$${amount + addedInsuranceAmount}`;
    } else {
      if (priceWrapper) {
        priceWrapper.innerHTML = '$0';
      }
      insuranceAmountLabel.innerHTML = '';
      this.#wrapper.querySelector('#total_amount_text').innerHTML = '$0';
    }
  }

  // Calculates min and max amount for selected device
  changeMinMaxQuantity() {
    var quantityInput = this.#wrapper.querySelector('.js-devices-count-field');
    var max;

    const minItemsDefault = Number(quantityInput.min);
    const maxItemsDefault = Number(quantityInput.max);

    if (min == undefined) {
      var min = minItemsDefault;
    }
    if (max == undefined) {
      max = maxItemsDefault;
    }
    if (min > max) {
      min = max;
    }

    if (Number(quantityInput.value) < Number(min)) {
      quantityInput.value = min;
    } else if (Number(quantityInput.value) > Number(max)) {
      quantityInput.value = max;
    }

    quantityInput.min = min;
    quantityInput.max = max;
  }

  showLoader() {
    document.querySelector('.js-modal-loader').classList.remove('hidden');
    this.#wrapper.querySelector('#new_lockbox_order_form').classList.add('disabled');
  }

  hideLoader() {
    document.querySelector('.js-modal-loader').classList.add('hidden');
    const form = this.#wrapper.querySelector('#new_lockbox_order_form');
    if (form) {
      form.classList.remove('disabled');
    }
  }

  initModal() {
    this.bindModalEvents();

    this.toggleCodeboxNote();
    this.toggleAddressBehavior();
    this.changeMinMaxQuantity();
    this.toggleInsuranceOption();
    this.setTotalAmountText();

    this.toggleKeyNumber();
    this.toggletableLockboxSameKey();

    // Update selected device name for shipping modal window
    document.querySelector(`#${this.#wrapper.id} .js-modal-content .js-selected-device-name`).innerHTML
      = this.#selectedDeviceField.closest('.js-device-info').querySelector('.js-device-name').innerText;

    this.hideLoader();
  }

  toggletableLockboxSameKey() {
    var codeboxType = this.getSelectedDeviceType();
    let tableLockboxSameKey = this.#wrapper.querySelector('table.lockbox_same_key');

    if (codeboxType == this.#types.TYPE_VAULT) {
      LayoutUtils.show(tableLockboxSameKey);
    } else {
      LayoutUtils.hide(tableLockboxSameKey);
    }
  }

  toggleKeyNumber() {
    var checkedSameKeyEnabled = this.#wrapper.querySelector('input.same_key_enabled').checked;
    let pLockboxSameKeyNumber = this.#wrapper.querySelector('.lockbox-same-key-number');
    if (checkedSameKeyEnabled) {
      LayoutUtils.show(pLockboxSameKeyNumber);
    } else {
      LayoutUtils.hide(pLockboxSameKeyNumber);
    }
  }

  // Events for form on the Account Setup page
  bindAccountSetupEvents() {
    const accountForm = this.accountSetupOrderForm;
    if (accountForm) {
      this.#wrapper = accountForm;
      this.#device = new DeviceConstructor(this.getSelectedDeviceType(), this.#types, this.#account);
      accountForm.querySelector('#lockbox_order_form_lockbox_type').addEventListener('change', () => {
        this.#device = new DeviceConstructor(this.getSelectedDeviceType(), this.#types, this.#account);
        this.toggleInsuranceOption();
        this.setTotalAmountText(true);
        this.toggleAddressBehavior();
        this.toggleKeyNumber();
        this.toggleCodeboxNote();
        this.toggletableLockboxSameKey();
      });

      this.insuranceOptionCheckbox?.addEventListener('change', () => this.setTotalAmountText(true));

      this.toggleInsuranceOption();
      this.toggleKeyNumber();
      this.toggleCodeboxNote();
      this.toggletableLockboxSameKey();
      this.setTotalAmountText(true);

      accountForm.querySelector('#lockbox_order_form_item_count').addEventListener('change', () => {
        this.setTotalAmountText(true);
      });
    }
  }

  bindEvents() {
    const btns = document.querySelectorAll('.js-order-device-btn');
    btns.forEach((btn) => {
      btn.addEventListener('click', (e) => {
        e.preventDefault();
        this.#selectedDeviceField = btn.closest('.js-device-info').querySelector('.js-selected-device');
        const selectedDevice = this.#selectedDeviceField.value;
        const modalId = `${selectedDevice}-sm-modal`;
        document.dispatchEvent(new CustomEvent('sm-modal:open', {detail: {content:
          document.querySelector('.js-order-modal-content').innerHTML,
          modalId
        }}));
        this.#wrapper = document.querySelector(`#${modalId}`);
        this.#wrapper.querySelector('.js-selected-device').value = selectedDevice;
        this.#wrapper.querySelector('.js-order-device-shipping-time').innerHTML = btn.closest('.js-device-info').querySelector('.js-device-shipping-time').innerText;
        this.#device = new DeviceConstructor(this.getSelectedDeviceType(), this.#types, this.#account);
        this.initModal();
        this.deviceChangeHandler(selectedDevice);
        return false;
      });
    });

    document.addEventListener('html-updated', () => {
      if (this.#wrapper) {
        this.initModal();
      }
    });

    window.addEventListener('resize', () => {
      DeviceUtils.setDescriptionLinesHeigth();
    });

    document.addEventListener('sidebar:state-changed', () => {
      DeviceUtils.setDescriptionLinesHeigth();
      this.#devicesCarousels.forEach(splide => {
        splide.refresh();
      });
    });

    this.bindAccountSetupEvents();
    this.initTabs();
  }

  initTabs() {
    document.querySelectorAll('.js-devices-group').forEach(el => {
      el.addEventListener('click', () => {
        document.querySelectorAll('.js-devices-group').forEach(g => {
          g.classList.toggle('current');
        });

        document.querySelectorAll('.js-devices').forEach(d => {
          d.classList.toggle('hidden');
        });

        DeviceUtils.setDescriptionLinesHeigth();
      });
    });
  }

  toggleCodeboxNote() {
    this.#device?.toggleCodeboxNote();
  }

  toggleAddressBehavior() {
    const isCanadianLockboxOrderAllowed = JSON.parse(document.querySelector('#codebox-order').dataset.isCanadianLockboxOrderAllowed);
    if (!isCanadianLockboxOrderAllowed) {
      this.#device.toggleAddressBehavior();
    }
  }

  getSelectedDeviceType() {
    return this.#wrapper.querySelector('.js-selected-device').value;
  }

  toggleInsuranceOption() {
    const deviceType = this.getSelectedDeviceType();
    const insuranceOption = document.querySelector('.js-insurance-option');

    if (this.#codeboxTypes.includes(deviceType)) {
      insuranceOption.classList.add('hidden');
      this.insuranceOptionCheckbox.checked = false;
    } else {
      insuranceOption.classList.remove('hidden');
    }
  }

  deviceChangeHandler(device) {
    this.#device = new DeviceConstructor(device, this.#types, this.#account);
    this.toggleCodeboxNote();
    this.toggleAddressBehavior();
    this.toggleInsuranceOption();

    this.toggletableLockboxSameKey();
  }

  bindModalEvents() {
    this.#wrapper?.querySelector('.js-submit-order')?.addEventListener('click', (e) => {
      e.preventDefault();
      const form = this.#wrapper.querySelector('#new_lockbox_order_form');
      this.showLoader();

      const data = new URLSearchParams(new FormData(form));
      // NOTE: Temporary, need to remove when Onboarding device order will be refactored
      data.append('settings', true);
      fetch(form.action, {method: 'PUT', headers: {
        'X-CSRF-Token': FetchUtils.CSRFToken()
      }, body: data}).then(FetchUtils.checkResponseStatus)
      .then((resp) => {
        return resp.json();
      }).then(json => {
        if (!json.has_errors) {
          if (!json.form_submited) {
            document.querySelector('.js-sm-modal .js-modal-content').innerHTML = '<div class="sm-modal-content">Please contact ShowMojo support for an estimate of shipping costs</div>';
          } else {
            document.querySelector('.js-sm-modal .js-modal-content').innerHTML = '<div class="sm-modal-content">Your order has been submitted. You\'ll receive a follow up from us within a few days.</div>';
          }
          document.dispatchEvent(new CustomEvent('hide-loader'));
        } else if (json.has_lockbox_order_form_errors) {
          const msg = `Your card was declined. Please update your billing information on the <a href="${json.settings_billing_path}">Billing Page</a>.`;
          document.querySelector('.js-sm-modal .js-modal-content').innerHTML = `<div class="sm-modal-content">${msg}</div>`;
          document.dispatchEvent(new CustomEvent('hide-loader'));
        } else {
          document.querySelector('.js-sm-modal .js-modal-content').innerHTML = json.modal_html;
          document.dispatchEvent(new CustomEvent('html-updated', {detail: {keepOpened: true}}));
        }
      }).catch((err) => {
        FetchUtils.handleResponseError(err);
      });
      return false;
    });

    this.insuranceOptionCheckbox?.addEventListener('change', () => this.setTotalAmountText());

    document.querySelector('input.same_key_enabled')?.addEventListener('change', () => {
      this.toggleKeyNumber();
      this.setTotalAmountText();
    });

    document.querySelector('.js-sm-modal .js-devices-count-field')?.addEventListener('change', () => {
      this.toggleCodeboxNote();
      this.toggleAddressBehavior();
      this.setTotalAmountText();
    });

    document.addEventListener('hide-loader', () => {
      this.hideLoader();
    });
  }

  renderPrices() {
    document.querySelectorAll('.js-device-price').forEach(p => {
      const infoWrapper = p.closest('.js-device-info');
      const deviceCode = infoWrapper.querySelector('.js-selected-device').value;
      const price = this.getDevicePrice(new DeviceConstructor(deviceCode, this.#types, this.#account), deviceCode, 1);
      infoWrapper.querySelector('.js-device-price').innerHTML = `$${price}`;
    });
  }

  initDevicesCarousels() {
    document.querySelectorAll('.js-devices-carousels').forEach(el => {
      const columns = el.querySelectorAll('.js-device-info');
      let breakpoints = {
        710: {
          perPage: 1,
      }};
      let perPage = columns.length < 2 ? 1 : 2;
      const splide = new Splide(el, {
        pagination: false,
        perPage,
        breakpoints
      }).mount();

      this.#devicesCarousels.push(splide);
    });
  }

  get insuranceOptionCheckbox() {
    return document.querySelector('#lockbox_order_form_shipping_protection');
  }

  get accountSetupOrderForm() {
    return document.querySelector('.js-account-setup-order-form');
  }

  init(orderPage) {
    this.#account = JSON.parse(orderPage.dataset.account);
    this.#types = JSON.parse(orderPage.dataset.types);
    this.#codeboxTypes = JSON.parse(orderPage.dataset.codeboxTypes);
    this.#insurancePercentage = orderPage.dataset.insurancePercentage;
    this.#sameKeyAdditionalFee = JSON.parse(orderPage.dataset.sameKeyAdditionalFee);
    this.#scaledPricing = JSON.parse(orderPage.dataset.scaledPricing);

    this.bindEvents();
    this.renderPrices();
    this.initDevicesCarousels();

    DeviceUtils.setDescriptionLinesHeigth();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const orderPage = document.querySelector('#codebox-order');
  const od = new OrderDevice();
  if (orderPage) {
    od.init(orderPage);
  }
});
