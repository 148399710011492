class ImportProviders {
  bindEvents() {
    const settings = document.querySelector('#import_settings');

    // Buildium Full Integration feature.
    // Having buildium-registration-url data attribute means
    // that the page was opened from the Buildium Marketplace
    // and the customer is trying to add Buildium API listing importer.
    if (settings.dataset.buildiumRegistrationUrl) {
      document.dispatchEvent(new CustomEvent('sm-modal:close'));
      document.dispatchEvent(
        new CustomEvent('sm-modal:open', { detail: { url: settings.dataset.buildiumRegistrationUrl } }),
      );
    }
  }

  initRentManagerEvents() {
    const settings = document.querySelector('#import_settings');
    document.querySelector('#import_provider_rent_manager_token')?.addEventListener('change', function (e) {
      if (e.target.value == settings.dataset.partnerWebAccess) {
        LayoutUtils.hide(document.querySelectorAll('.api_credentials'));
      } else {
        LayoutUtils.show(document.querySelectorAll('.api_credentials'));
      }
    });
    document.querySelector('#import_provider_rent_manager_token')?.dispatchEvent(new Event('change'));
  }

  initProviderEvents() {
    this.initRentManagerEvents();
  }

  init() {
    this.bindEvents();
    this.initProviderEvents();
  }
}

document.addEventListener('DOMContentLoaded', function () {
  const settings = document.querySelector('#import_settings');
  if (settings) {
    const ip = new ImportProviders();
    ip.init();

    document.addEventListener('html-updated', (e) => {
      if (e.detail?.container == '.editable_form.import-provider') {
        ip.initRentManagerEvents();
      }
    });
  }
});
