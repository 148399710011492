import LayoutUtils from '../../../utils/layout_utils';

class ApplicantsUpdates {

  bindApplicantsChange(wrapper) {
    const applicants = document.querySelectorAll(`${wrapper} .js-listing-applicants`);

    applicants.forEach((applicant) => {
      applicant.addEventListener('values-changed', function() {
        const skipSnooze = applicant.closest('.skippable').querySelectorAll('.skip input[type=button], .snooze input[type=button]');
        var modifiedTiles = applicant.querySelectorAll('.contact-control-group.js-was-modified');

        if (modifiedTiles.length > 0) {
          LayoutUtils.disableButton(skipSnooze);
        } else {
          LayoutUtils.enableButton(skipSnooze);
        }

        const updateTracking = applicant.querySelectorAll('.js-manual-update-tracking input');
        if ([...modifiedTiles].find((el) => {return el.querySelector('.contact-show');})) {
          // Persistent tiles with changes detected
          // Let's manually mark APPLICANTS section as "updated"
          updateTracking.forEach((el) => {
            el.removeAttribute('disabled');
          });
        } else {
          // No successful changes detected
          updateTracking.forEach((el) => {
            el.setAttribute('disabled', 'disabled');
          });
        }
      });
    });
  }

  bindApplicantAdd(wrapper) {
    const applicants = document.querySelector(wrapper).querySelectorAll('.js-add-applicant-from-search, .js-add-applicant-manually');
    applicants.forEach(a => {
      a.addEventListener('click', () => {
        const skipSnooze = a.closest('.skippable').querySelectorAll('.skip input[type=button], .snooze input[type=button]');
        LayoutUtils.disableButton(skipSnooze);
      });
    });
  }

  bindEvents(wrapper) {
    this.bindApplicantsChange(wrapper);
    this.bindApplicantAdd(wrapper);
  }

  init(wrapper) {
    document.querySelectorAll(`${wrapper} .js-listing-applicants`).forEach(function(app) {
      const listingId = app.dataset.listingId;
      const form = new RequiredListingUpdatesApplicantsResidentsForm('.js-listing-applicants', listingId); // eslint-disable-line
      form.init();
    });
    this.bindEvents(wrapper);
  }
}

export default ApplicantsUpdates;
