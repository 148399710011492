import LayoutUtils from '../../../utils/layout_utils';

class ResidentsUpdates {
  bindResidentsChange(wrapper) {
    const residents = document.querySelectorAll(`${wrapper} .js-listing-residents`);
    residents.forEach(r => {
      r.addEventListener('values-changed', function() {
        const skipSnooze = r.closest('.skippable').querySelectorAll('.skip input[type=button], .snooze input[type=button]');
        const modifiedTiles = r.querySelectorAll('.js-was-modified');
        if (modifiedTiles.length > 0) {
          LayoutUtils.disableButton(skipSnooze);
        } else {
          LayoutUtils.enableButton(skipSnooze);
        }

        const updateTracking = r.querySelectorAll('.js-manual-update-tracking input');
        if ([...modifiedTiles].find((el) => {return el.querySelector('.contact-show');})) {
          // Persistent tiles with changes detected
          // Let's manually mark RESIDENTS section as "updated"
          updateTracking.forEach((el) => {
            el.removeAttribute('disabled');
          });
        } else {
          // No successful changes detected
          updateTracking.forEach((el) => {
            el.setAttribute('disabled', 'disabled');
          });
        }
      });
    });
  }

  bindResidentAdd(wrapper) {
    const residents = document.querySelector(`${wrapper} .js-listing-residents`);
    if (residents) {
      const elems = residents.querySelectorAll('.js-add-applicant-from-search, .js-add-applicant-manually');
      elems.forEach((elem) => {
        elem.addEventListener('click', function() {
          const skipSnooze = elem.closest('.skippable').querySelectorAll('.skip input[type=button], .snooze input[type=button]');
          LayoutUtils.disableButton(skipSnooze);
        });
      });
    }
  }

  bindEvents(wrapper) {
    this.bindResidentsChange(wrapper);
    this.bindResidentAdd(wrapper);
  }

  init(wrapper) {
    document.querySelectorAll(`${wrapper} .js-listing-residents`).forEach(function(resident) {
      var listingId = resident.dataset.listingId;
      var form = new RequiredListingUpdatesResidents(listingId); // eslint-disable-line
      form.init();
    });

    this.bindEvents(wrapper);
  }
}

export default ResidentsUpdates;
