class BrandEssentialsForm {
  constructor(wrapper) {
    this.wrapper = wrapper;
  }

  init() {
    if (!this.form) return;

    this.bindEvents();

    if (this.form.classList.contains('edit-mode')) {
      this.handleFormEdit();
    } else {
      this.handleFormCancel();
    }
  }

  bindEvents() {
    this.editButton?.addEventListener('click', this.handleFormEdit.bind(this));
    this.cancelButton?.addEventListener('click', this.handleFormCancel.bind(this));
  }

  handleFormEdit() {
    this.form.dispatchEvent(new CustomEvent('partial-form:enable'));
    LayoutUtils.hide(this.editButton);
  }

  handleFormCancel() {
    LayoutUtils.show(this.editButton);
  }

  get form() {
    return this.wrapper.querySelector('.js-sm-partial-form');
  }

  get editButton() {
    return document.getElementById('edit-essentials');
  }

  get cancelButton() {
    return this.form.querySelector('.partialform-button-cancel');
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const wrapper = document.getElementById('js-brand-essentials');
  if (wrapper) {
    wrapper.addEventListener('updated', () => {
      const formInstance = new BrandEssentialsForm(wrapper);
      formInstance.init();
    });
    wrapper.dispatchEvent(new CustomEvent('updated'));
  }
});
