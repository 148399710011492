import QuestionnaireUtils from '../utils';

class Bedrooms {
  getHtml({newScreeningQuestionsEnabled}, questionDiv) {
    const text = questionDiv.querySelector('[data-binding="text"]');
    text.value = 'How many bedrooms are you looking for?';
    const wrapper = document.createElement('span');
    wrapper.classList.add('system_question');
    text.before(wrapper);
    wrapper.append(text);
    text.insertAdjacentHTML('afterend', '<br><small>(Prospects can select from a studio to 5+ bedrooms)</small>');
    if (JSON.parse(newScreeningQuestionsEnabled)) {
      questionDiv.querySelector('a[data-binding="delete_link"]')
        .insertAdjacentHTML('afterend', '<br/><span class="custom_budget_question"> <small> <input type="checkbox" name="budget"/> Show only bedrooms options that are on-the-market and ask for the budget </small> </span>');
    }

    return questionDiv;
  }

  init(wrapper) {
    this.bindEvents(wrapper);
  }

  createAdditionalQuestion(elem){
    let questionDiv = document.querySelector('#additional_question_bedrooms').cloneNode(true);
    questionDiv.classList.remove('hidden');
    var addit_type = 'DROPDOWN';

    const wrapper = document.querySelector('#questionnaire_holder');
    questionDiv.setAttribute('id' , 'aq_' + wrapper.dataset.aqIndx);
    questionDiv.querySelector('[data-binding="question_type"]').value = addit_type;
    questionDiv.querySelector('[name="question[][childvariant]"]').value = '';

    let parent_id = elem.querySelector('[name="question[][id]"]').value;
    let is_child = elem.querySelector('[name="question[][parent_text]"]').value;

    questionDiv.querySelector('[name="question[][parent_id]"]').value = parent_id;
    if(is_child){
      questionDiv.querySelector('[name="question[][is_child]"]').value = is_child;
    }

    questionDiv.querySelector('[data-binding="text"]').value = 'What is your ideal budget?';

    wrapper.setAttribute('data-aq-indx', wrapper.dataset.aqIndx++);
    return questionDiv;
  }

  bindEvents(questionDiv) {
    const createAdditionalQuestion = this.createAdditionalQuestion;
    const budgetInput = questionDiv.querySelector('input[name=budget]');
    if (budgetInput) {
      budgetInput.addEventListener('change', function(e) {
        if(e.target.checked){
          let question = createAdditionalQuestion(e.target.closest('.questionbox'));
          e.target.closest('.questionbox').querySelector('.dropdown_additional_questions').append(question);
        } else {
          e.target.closest('.questionbox').querySelector('.dropdown_additional_questions').innerHTML = '';
          let id = e.target.closest('.questionbox').querySelector('[name="question[][id]"]').value;
          if(id.match(/[0-9]/)){
            QuestionnaireUtils.eraseAllAdditionalQuestion(id);
          }
        }
      });
    }
  }

  isSingleQuestion() {
    return true;
  }
}

window.Bedrooms = Bedrooms;
