import LayoutUtils from '../../utils/layout_utils';

class ListingGroups {
  bindEvents() {
    document.addEventListener('editable-list-items:item-removed', (e) => {
      if (e.detail.wrapper.querySelector('.js-listing-group-item')) {
        const data = e.detail.data;
        const el = document.querySelectorAll(`.js-listing-group-item[data-id='${data.listing_group_id}']`);
        LayoutUtils.hide(el);
        el.forEach(item => item.remove());
        if (JSON.parse(data.listing_groups_blank)) {
          let html  = '<div class="partialform-show"><p>You have no listing groups yet. ';
          html += `You can add groups on the <a href="${data.listings_path}">Listings Page</a>`;
          html += '.</p></div>';
          document.querySelector('#listing_groups_section').innerHTML = html;
        }
      }
    });

    document.addEventListener('editable-list-items:item-updated', (e) => {
      if (e.detail.wrapper.querySelector('.js-listing-group-item')) {
        const data = e.detail.data;
        document.querySelector('#listing_groups_settings').innerHTML = data.listing_groups_html;
        document.dispatchEvent(new CustomEvent('html-updated', {detail: {container: '#listing_groups_settings'}}));
        document.querySelector('#listing_groups_settings #listing_groups_section')?.dispatchEvent(new CustomEvent('partial-form:enable'));
      }
    });
  }

  init() {
    this.bindEvents();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  if (document.querySelector('.js-listing-group-item')) {
    new ListingGroups().init();

    document.addEventListener('html-updated', e => {
      if (e.detail?.container == '#listing-groups') {
        new ListingGroups().init();
      }
    });
  }
});
