class ShowingConfirmations {
  constructor(wrapper) {
    this.wrapper = wrapper;
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    this.form.addEventListener('enabled', this.toggleConfirmOrBeCanceled.bind(this));
    this.form.addEventListener('disabled', this.toggleConfirmOrBeCanceled.bind(this));
    this.form.querySelector('#js-confirmation-required')
      .addEventListener('change', this.toggleConfirmOrBeCanceled.bind(this));
  }

  toggleConfirmOrBeCanceled() {
    const value = this.form.querySelector('#js-confirmation-required').value;
    const confirmOrBeCanceled = this.form.querySelector('#confirm-or-be-cancelled');
    if (value === 'true') {
      LayoutUtils.show(confirmOrBeCanceled);
    }
    else {
      LayoutUtils.hide(confirmOrBeCanceled);
    }
  }

  get form() {
    return this.wrapper.querySelector('#confirmation-form');
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const wrapper = document.getElementById('confirmation-settings');
  if (wrapper) {
    const settingsInstance = new ShowingConfirmations(wrapper);
    settingsInstance.init();
    wrapper.addEventListener('updated', () => settingsInstance.init());
  }
});
