class TenantNotificationForm {
  constructor(wrapper) {
    this.form = wrapper.querySelector('form');
  }

  init() {
    this.initTemplateEditors();
    this.bindEvents();
  }

  initTemplateEditors() {
    const bodyEditorSettings = { toolbar: ['bold', 'italic', 'insert', 'placeholder', 'restoreDefaultTemplate'] };
    const emailBodyEditor = new window.SMTemplateRichEditor().init('js-tenant-notification', bodyEditorSettings);

    const subjectEditorSettings = {
      toolbar: ['placeholder', 'restoreDefaultTemplate'],
      editorHeight: 60,
      blockedKeystrokes: ['Enter']
    };
    const emailSubjectEditor = new window.SMTemplateRichEditor().init('js-tenant-notification-subject', subjectEditorSettings);

    this.templateEditors = [emailBodyEditor, emailSubjectEditor];
  }

  setTemplateEditorsToEditMode() {
    this.templateEditors.forEach(editor => editor.setEditMode());
  }

  resetTemplateEditors() {
    this.templateEditors.forEach(editor => {
      editor.resetContent();
      editor.setShowMode();
    });
  }

  bindEvents() {
    this.form.addEventListener('cancelled', this.resetTemplateEditors.bind(this));
    this.form.addEventListener('enabled', this.setTemplateEditorsToEditMode.bind(this));
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const wrapper = document.getElementById('tenant-notification');
  if (wrapper) {
    wrapper.addEventListener('updated', () => {
      const formInstance = new TenantNotificationForm(wrapper);
      formInstance.init();
    });
    wrapper.dispatchEvent(new CustomEvent('updated'));
  }
});
