class FeedbackSurves {
  #finishSurvey = false;
  #lastValue;
  #currentQuestion = 1;
  #lastQuestionAnswered = 0;
  #formLocked = false;
  #boostThreshold;
  #isReputationBoostEnabled;
  #nextQuestion;

  makeNextButtonVisible() {
    const nextButton = document.querySelector('.next');
    LayoutUtils.show(nextButton);
    if (!this.isElementInViewport(nextButton)) {
      nextButton.scrollIntoView();
    }
  }

  feedbackBorderColor(value) {
    let color = '#CC9933';
    switch(value) {
      case 5:
        color = '#33541d';
        break;
      case 4:
        color = '#466738';
        break;
      case 3:
        color = '#8f7549';
        break;
      case 2:
        color = '#795b4c';
        break;
      case 1:
        color = '#55364d';
        break;
    }
    return color;
  }

  isElementInViewport (el) {
    const rect = el.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  nextClickHandler(e) {
    LayoutUtils.hide(document.querySelector('.question_' + this.#currentQuestion));
    if (!this.#finishSurvey && document.querySelectorAll('.question').length !== this.#currentQuestion) {
      this.#nextQuestion = this.#currentQuestion + 1;
      LayoutUtils.show(document.querySelector('.question_' + this.#nextQuestion));
      if (this.#nextQuestion > this.#lastQuestionAnswered) {
        document.querySelector('.item_' + this.#nextQuestion).style.backgroundColor = 'white';
      }
      if (document.querySelectorAll('.question').length === this.#nextQuestion) {
        document.querySelector('.next span').innerText = 'Finish';
      } else {
        document.querySelector('.next span').innerText = 'Next';
      }
      this.#currentQuestion = this.#nextQuestion;
      if (this.#nextQuestion > this.#lastQuestionAnswered) {
        LayoutUtils.hide(document.querySelector('.next'));
      }
    } else {
      this.#formLocked = true;
      LayoutUtils.show(document.querySelector('.thank_you'));
      Rails.fire(e.target.closest('form'), 'submit');
      LayoutUtils.hide(document.querySelector('.next'));

      let feedbackValueSum = 0;
      document.querySelectorAll('.feedback_survey .questions_container input.js-answer-score').forEach((el) => {
        feedbackValueSum += Number(el.value);
      });
      const avgFeedback = feedbackValueSum / document.querySelectorAll('.question').length;

      if (!this.#finishSurvey && this.#isReputationBoostEnabled && (avgFeedback >= this.#boostThreshold)) {
        LayoutUtils.show(document.querySelector('.thank_you .reputation-boost-wrapper'));
        LayoutUtils.hide(document.querySelector('.thank_you .standard-last-page-wrapper'));
      } else {
        LayoutUtils.hide(document.querySelector('.thank_you .reputation-boost-wrapper'));
        LayoutUtils.show(document.querySelector('.thank_you .standard-last-page-wrapper'));
      }
    }
  }

  bindEvents() {
    document.querySelector('.next').addEventListener('click', this.nextClickHandler.bind(this));
    document.querySelectorAll('.value_container').forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        const container = e.currentTarget;
        this.#finishSurvey = false;
        document.querySelectorAll(`.question_${this.#currentQuestion} .value_container`).forEach(question => {
          question.style.border = '3px solid white';
        });

        document.querySelector('.item_' + this.#currentQuestion).style.backgroundColor = window.getComputedStyle(container, null).getPropertyValue('background-color');
        this.#lastValue = Number(container.dataset.value);
        LayoutUtils.hide(document.querySelector(`.question_${this.#currentQuestion} .subquery`));
        LayoutUtils.show(document.querySelector(`.question_${this.#currentQuestion} .subquery_for_${this.#lastValue}`));
        document.querySelector(`.question_${this.#currentQuestion} input.js-answer-score`).value = this.#lastValue;
        container.style.border = '3px solid ' + this.feedbackBorderColor(this.#lastValue);
        document.querySelector(`.question_${this.#currentQuestion} .feedback_icon`).style.color = 'transparent';
        document.querySelector(`.question_${this.#currentQuestion} .feedback_icon_${this.#lastValue}`).style.color = this.feedbackBorderColor(this.#lastValue);
        if (this.#lastQuestionAnswered < this.#currentQuestion) {
          this.#lastQuestionAnswered = this.#currentQuestion;
        }
        this.makeNextButtonVisible();

        return false;
      });
    });

    document.querySelector('.show_noshow_value')?.addEventListener('click', function(e) {
      const value = e.target.dataset.value;
      if ((value == '1') || (value == '2')) {
        this.#finishSurvey = true;
      }
    });

    document.querySelectorAll('.progress_bar .item').forEach((item) => {
      item.addEventListener('click', () => {
        const value = Number(item.innerText);
        if (!this.#formLocked && (value < this.#currentQuestion)) {
          this.#nextQuestion = value;
          LayoutUtils.hide(document.querySelector('.question_' + this.#currentQuestion));
          LayoutUtils.show(document.querySelector('.question_' + this.#nextQuestion));
          this.#currentQuestion = this.#nextQuestion;
          if (document.querySelectorAll('.question').length === this.#nextQuestion) {
            document.querySelector('.next span').innerText = 'Finish';
          } else {
            document.querySelector('.next span').innerText = 'Next';
          }
          LayoutUtils.show(document.querySelector('.next'));
        }
      });
    });
  }

  init(wrapper) {
    this.#boostThreshold = wrapper.dataset.boostThreshold;
    this.#isReputationBoostEnabled = JSON.parse(wrapper.dataset.isReputationBoostEnabled);
    LayoutUtils.show(document.querySelector('.question_1'));
    const firstItem = document.querySelector('.item_1');
    if (firstItem) {
      firstItem.style.backgroundColor = 'white';
    }
    this.bindEvents();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const wrapper = document.querySelector('.js-feedback-survey');
  if (wrapper) {
    new FeedbackSurves().init(wrapper);
  }
});

