import LayoutUtils from '../../utils/layout_utils';
import InfiniteLoaderHandlers from 'src/shared/infinite_loader/infinite_loader_handlers';

class ListingHistory {
  #historyListSelector;

  hideLoader() {
    LayoutUtils.hide(document.querySelector('.js-history-loader'));
  }

  initLoader() {
    new InfiniteLoaderHandlers().init(this.#historyListSelector, (urlData, container, onComplete) => {
      const url = new URL(container.closest(this.#historyListSelector).dataset.url);
      url.searchParams.append('page', urlData.page);

      fetch(url.href, {headers: {'Content-Type': 'application/json'}}).then(FetchUtils.checkResponseStatus)
        .then((resp) => {
          return resp.json();
        }).then(data => {
          if (data.html) {
            document.querySelector(`${this.#historyListSelector} .js-infinite-list-inner #cnt_container`).insertAdjacentHTML('beforeend', data.html);
          }
          if (data.disable_scroll) {
            this.hideLoader();
            document.querySelector(this.#historyListSelector).dispatchEvent(new CustomEvent('infinite-loader:disconnect'));
          }

          if (onComplete) {
            onComplete();
          }
        }).catch((err) => {
          this.hideLoader();
          FetchUtils.handleResponseError(err);
        });
    });
  }

  init(historyListSelector) {
    this.#historyListSelector = historyListSelector;
    this.initLoader();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const historyListSelector = '.js-listing-history-infinite-list';
  if (document.querySelector(historyListSelector)) {
    new ListingHistory().init(historyListSelector);
  }
});
