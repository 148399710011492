class EditProperties {
  constructor() {
    this.refreshMapWidget = this.refreshMapWidget.bind(this);
  }

  refreshMapWidget() {
    // Update map widget
    let locations = [];
    let i = 1;
    [...document.querySelectorAll('.partialform-edit .address_item')].filter((el) => el.style.display !== 'none').forEach(function(el) {
      let lat = el.dataset.lat,
        long = el.dataset.long;
      locations.push([lat, long, i]);
      i++;
    });

    const new_address = document.querySelector('#address_address').value,
      city = document.querySelector('#address_city').value,
      state = document.querySelector('#address_state').value;

    if(new_address && city && state) {
      this.addNewLocationToMap(locations, i, { address: new_address, city: city, state: state });
    } else {
      this.updateStaticMapUrl(locations);
    }
  }

  createMapboxMarker(latitude, longitude, label) {
    return 'pin-m-' + label + '+ff0000(' + [longitude, latitude].join(',') + ')';
  }

  updateStaticMapUrl(original_locations) {
    let locations = original_locations.slice(0, 99).filter(function(location) {
      return !isNaN(parseFloat(location[0]) * parseFloat(location[1]));
    });

    let property_map = document.getElementById('property_map');
    if (property_map) {
      const map_provider = property_map.getAttribute('data-provider');
      let map_url;
      if(locations.length === 0) {
        map_url = this.blankMapUrl();
      } else if(map_provider === 'HERE') {
        map_url = this.hereMapUrl(property_map, locations);
      } else { // MapBox or unexpected provider
        map_url = this.mapboxMapUrl(property_map, locations);
      }

      property_map.src = map_url;
    }
  }

  hereMapUrl(map, locations) {
    const width = map.getAttribute('width') || '270';
    const height = map.getAttribute('height') || '215';
    const geoJson = {
      'type': 'FeatureCollection',
      'features': locations.map(item => ({
        'type': 'Feature',
        'geometry': {
          'type': 'Point',
          'coordinates': [Number(item[1]), Number(item[0])]
        }
      }))
    };

    return `${here_base_url}overlay:padding=20/${width}x${height}/png?apiKey=${here_api_key}&geojson=${JSON.stringify(geoJson)}`;
  }

  mapboxMapUrl(map, locations) {
    let createMapboxMarker = this.createMapboxMarker;
    const size = map.getAttribute('data-size') || '270x215';
    const position = locations.length === 1 ? [locations[0][1], locations[0][0], 15].join(',') : 'auto';
    let markers  = locations.map(function(location_data) {
      return createMapboxMarker.apply(this, location_data);
    });
    return mapbox_base_url + '/' + markers.join(',') + '/' + position + '/' + size +'@2x?access_token=' + mapbox_api_key;
  }

  addNewLocationToMap(locations, label, params) {
    fetch('/geocode'+ '&' + new URLSearchParams({'address': params}), {headers: {
      'Content-Type': 'application/json; charset=utf-8'
    }}).then(FetchUtils.checkResponseStatus)
    .then((resp) => {
      return resp.json();
    }).then(coordinates => {
      if(coordinates.hasOwnProperty('longitude')) {
        locations.push([coordinates.latitude, coordinates.longitude, label]);
        this.updateStaticMapUrl(locations);
        document.getElementById('address_lat').value = coordinates.latitude;
        document.getElementById('address_long').value = coordinates.longitude;
      } else {
        document.getElementById('property_map').src = this.blankMapUrl();
        document.getElementById('address_lat').value = '';
        document.getElementById('address_long').value = '';
      }
    }).catch((err) => {
      FetchUtils.handleResponseError(err);
    });
  }

  blankMapUrl() {
    return '/assets/blank.png';
  }

  resetForm() {
    let address_picker = document.querySelector('#address_picker');

    LayoutUtils.show(document.querySelector('#address_picker .address_item:hidden'));
    document.querySelector('#address_picker .address_item input:disabled').removeAttribute('disabled');
    if (document.querySelector('#address_picker .address_item')) {
      LayoutUtils.show(address_picker);
    }
    LayoutUtils.hide(document.querySelector('#new_address_fields'));
    document.querySelector('#new_address_fields input').value = '';
    document.querySelector('#new_address_fields select').value = '';
    if(address_picker.dataset.available === 1) {
      document.querySelector('#add_existing_address').classList.add('disabled');
    }
    else {
      document.querySelector('#add_existing_address').classList.remove('disabled');
    }
  }

  initAddressPicker() {
    // Address Picker
    let address_picker = document.querySelector('#address_picker');
    address_picker.addEventListener('address.add', this.refreshMapWidget);
    address_picker.addEventListener('address.remove', this.refreshMapWidget);
    ['address.add, address.remove'].forEach(evt => {
      address_picker.addEventListener(evt, function(){
        if(address_picker.dataset.available === 1) {
          document.querySelector('#add_existing_address').classList.add('disabled');
        }
        else {
          document.querySelector('#add_existing_address').classList.remove('disabled');
        }
      });
    });
    address_picker.addEventListener('address.remove', function(){
      if(address_picker.dataset.visible === 0) {
        address_picker.style.display = 'none';
      }
    });

    document.querySelector('#add_existing_address').addEventListener('click', function(e){
      if(!e.target.classList.contains('disabled')) {
        LayoutUtils.show(document.querySelector('#address_picker_holder'));
        LayoutUtils.show(document.querySelector('#available_address'));
        address_picker.style.display = 'block';
        address_picker.dispatchEvent(new CustomEvent('address-picker:show'));
      }

      return false;
    });

    document.querySelector('#add_new_address').addEventListener('click', function(){
      LayoutUtils.show(document.querySelector('#new_address_fields'));
      return false;
    });

    document.querySelectorAll('#address_address, #address_city, #address_state').forEach(el => {
      el.addEventListener('change', function(){
        address_picker.dispatchEvent(new CustomEvent('address.add'));
      });
    });
  }

  initPropertyEssentials() {
    let essentials_form = document.querySelector('#essentials > form');
    if (essentials_form) {
      essentials_form.addEventListener('disabled', function(){
        document.querySelector('#address_picker').dispatchEvent(new CustomEvent('address-picker:reset'));
        this.refreshMapWidget();
        this.resetForm();
      });
      document.querySelector('#edit-essentials').addEventListener('click', function(){
        essentials_form.dispatchEvent(new CustomEvent('partial-form:enable'));
      });
    }
  }

  initManagerPicker() {
    document.querySelector('#manager_picker .combobox_quick_search')?.addEventListener('change', function(evt) {
      const manager_id = evt.detail.item.dataset.id;
      evt.target.closest('.combobox_title').querySelector('#property_property_manager_id').value = manager_id;
    });
  }

  init() {
    this.initAddressPicker();
    this.initPropertyEssentials();
    this.initManagerPicker();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const form = document.querySelector('.edit-property-form');
  if (form) {
    const instance = new EditProperties();
    instance.init();
    document.addEventListener('edit-property-html-updated', () => {
      instance.init();
    });
  }
});
