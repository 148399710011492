import LayoutUtils from '../utils/layout_utils';

class PropertiesSettings {
  bindEvents(switcher_enabled_control) {
    document.addEventListener('editable-list-items:item-removed', (e) => {
      if (e.detail.wrapper.querySelector('.js-manager-item')) {
        const data = e.detail.data;
        document.querySelectorAll(`.js-manager-item[data-id="${data.id}"]`).forEach((el) => {
          LayoutUtils.hide(el);
          el.remove();
        });
      }
    });

    document.addEventListener('editable-list-items:item-updated', (e) => {
      if (e.detail.wrapper.querySelector('.js-manager-item')) {
        const data = e.detail.data;
        document.querySelector('#listing-property-settings').innerHTML = data.listing_property_settings_html;
        document.dispatchEvent(new CustomEvent('html-updated', {detail: {container: '#listing-property-settings'}}));
        document.querySelector('#listing-property-settings #property-setting-edit').dispatchEvent(new CustomEvent('partial-form:enable'));
      }
    });

    let manager_edit_form = document.querySelector("#property-setting-edit");
    manager_edit_form.addEventListener('enabled', function() {
      manager_edit_form.querySelector('div.partialform-edit').style.display = 'block';
      switcher_enabled_control.enable();
    });

    manager_edit_form.addEventListener('cancelled', function() {
      manager_edit_form.querySelector('div.partialform-show').style.display = 'block';
      switcher_enabled_control.disable();
    });

    document.querySelector(".partialform-button-add").addEventListener('click', function(){
      let tpl = document.querySelector("#new-property-manager-template").innerHTML;
      document.querySelector("#property-setting-section").innerHTML = tpl;
      document.dispatchEvent(new CustomEvent('html-updated', {detail: {container: '#property-setting-section' }}));

      document.querySelector('#property-setting-section form').dispatchEvent(new CustomEvent('partial-form:enable'));
      document.querySelector('#property-setting-section form').addEventListener('disable', function() {
        fetch(wrapper.dataset.url).then(FetchUtils.checkResponseStatus).then((response) => {
          manager_edit_form.dispatchEvent(new CustomEvent('partial-form:enable'));
          }).catch((err) => {
            FetchUtils.handleResponseError(err);
          });
      });
    });
  }

  init() {
    let assignEmailLeadsToPropertiesCheckbox = document.querySelector('#account_assign_email_leads_to_properties');

    let switcher_enabled_control = new Switchery(assignEmailLeadsToPropertiesCheckbox,
      {
        size: 'small'
      });
    switcher_enabled_control.disable();
    this.bindEvents(switcher_enabled_control);
  }
}

document.addEventListener('DOMContentLoaded', function() {
  if (document.querySelector('#listing-property-settings')) {
    new PropertiesSettings().init();

    document.addEventListener('html-updated', e => {
      if (e.detail?.container == '#listing-property-settings') {
        new PropertiesSettings().init();
      }
    });
  }
});
