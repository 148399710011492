class RescheduleRequestEmailForm {
  constructor(wrapper) {
    this.form = wrapper.querySelector('form');
  }

  init() {
    this.initTemplateEditor();
    this.bindEvents();
  }

  initTemplateEditor() {
    const settings = { toolbar: ['bold', 'italic', 'insert', 'placeholder', 'restoreDefaultTemplate'] };
    this.templateEditor = new window.SMTemplateRichEditor().init('js-reschedule-request-email-template', settings);
  }

  setTemplateEditorToEditMode() {
    this.templateEditor.setEditMode();
  }

  resetTemplateEditor() {
    this.templateEditor.resetContent();
    this.templateEditor.setShowMode();
  }

  bindEvents() {
    this.form.addEventListener('cancelled', this.resetTemplateEditor.bind(this));
    this.form.addEventListener('enabled', this.setTemplateEditorToEditMode.bind(this));
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const wrapper = document.getElementById('reschedule-request-email');
  if (wrapper) {
    wrapper.addEventListener('updated', () => {
      const formInstance = new RescheduleRequestEmailForm(wrapper);
      formInstance.init();
    });
    wrapper.dispatchEvent(new CustomEvent('updated'));
  }
});
