class OffMarketAutomation {
  constructor(wrapper, templateId) {
    this.form = wrapper.querySelector('form');
    this.templateId = templateId;
  }

  init() {
    this.initTemplateEditor();
    this.bindEvents();
  }

  initTemplateEditor() {
    document.querySelector('#' + this.templateId).addEventListener('sm-rich-editor:initialized', () => {
      this.form.querySelectorAll('.js-submit-btn').forEach(el => {
        el.classList.remove('disabled');
      });
    });
    const settings = {
      toolbar: ['bold', 'italic', 'insert', 'placeholder', 'restoreDefaultTemplate'],
      editorHeight: 300
    };
    this.templateEditor = new window.SMTemplateRichEditor().init(this.templateId, settings);
  }

  setTemplateEditorToEditMode() {
    this.templateEditor.setEditMode();
  }

  resetTemplateEditor() {
    this.templateEditor.resetContent();
    this.templateEditor.setShowMode();
  }

  bindEvents() {
    this.form.addEventListener('cancelled', this.resetTemplateEditor.bind(this));
    this.form.addEventListener('enabled', this.setTemplateEditorToEditMode.bind(this));

    const cancelShowingsSelector = this.form.querySelector('#js-imported-listings-cancel_showings');
    const emailTemplateWrapper = this.form.querySelector('#imported_listings_cancel_showings');
    cancelShowingsSelector?.addEventListener('change', ()=> {
      if(cancelShowingsSelector.value === 'true') {
        LayoutUtils.show(emailTemplateWrapper);
      } else {
        LayoutUtils.hide(emailTemplateWrapper);
      }
    });
    cancelShowingsSelector?.dispatchEvent(new Event('change'));
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const wrapper = document.getElementById('off-market-automation-settings');
  if (wrapper) {
    wrapper.addEventListener('updated', () => {
      const formInstance = new OffMarketAutomation(wrapper, 'js-cancel-showings-email-editor');
      formInstance.init();
    });
    wrapper.dispatchEvent(new CustomEvent('updated'));
  }
});

window.OffMarketAutomation = OffMarketAutomation;
