class DynamicProspectNotificationsForm {
  constructor(wrapper) {
    this.form = wrapper.querySelector('#js-dynamic-prospect-notifications-form');
    this.locatorServiceEnabled = this.form.dataset.locatorServiceEnabled === 'true';
  }

  init() {
    this.initSwitches();
    this.initTemplateEditors();
    this.bindEvents();
  }

  initSwitches() {
    if (this.locatorServiceEnabled) {
      this.form.querySelector('#annual_follow_up_row .switchery').classList.add('inactive');
      this.form.querySelector('#second_year_follow_up_row .switchery').classList.add('inactive');
    } else {
      this.form.querySelector('#annual_follow_up_row .switchery').classList.remove('inactive');
      this.form.querySelector('#second_year_follow_up_row .switchery').classList.remove('inactive');
    }
  }

  initTemplateEditors() {
    const settings = {
      toolbar: ['bold', 'italic', {'list': 'bullet'}, {'list': 'ordered'}, 'link', 'placeholder', 'insert', 'restoreDefaultTemplate']
    };

    this.templateEditors = [
      'brandable_market_new_listing_email_body',
      'brandable_reannounce_new_listing_email_body',
      'brandable_market_lower_rent_email_body',
      'brandable_market_off_market_listing_email_body',
      'brandable_annual_follow_up_email_body',
      'brandable_second_year_follow_up_email_body'
    ].map(templateId => new window.SMTemplateRichEditor().init(templateId, settings));
  }

  setTemplateEditorsToEditMode() {
    this.templateEditors.forEach(editor => editor.setEditMode());
  }

  resetTemplateEditors() {
    this.templateEditors.forEach(editor => {
      editor.resetContent();
      editor.setShowMode();
    });
  }

  bindEvents() {
    this.form.querySelector('#js-market-new-listing-toggle').addEventListener('change', () => this.toggleReannounceNewListingAvailability());
    this.form.querySelectorAll('input[type="checkbox"].switchery-checkbox').forEach(switcher => {
      switcher.addEventListener('change', () => this.toggleTemplateEditorVisibility(switcher));
      this.toggleTemplateEditorVisibility(switcher);
    });

    this.form.addEventListener('cancelled', () => {
      this.toggleReannounceNewListingAvailability();
      this.resetTemplateEditors();
    });

    this.form.addEventListener('enabled', () => {
      this.setTemplateEditorsToEditMode();
    });
  }

  toggleReannounceNewListingAvailability() {
    const newListingToggleActive = this.form.querySelector('#js-market-new-listing-toggle').checked;
    const reannounceSwitcher = this.form.querySelector('#reannounce_new_listing_row .switchery');

    if (newListingToggleActive && !this.locatorServiceEnabled) {
      reannounceSwitcher.classList.remove('inactive');
    } else {
      reannounceSwitcher.classList.add('inactive');
    }
    this.toggleTemplateEditorVisibility(reannounceSwitcher.previousSibling);
  }

  toggleTemplateEditorVisibility(switcher) {
    const targetId = switcher.dataset.targetTemplate;
    if(!targetId) return;

    // Not disabled and checked
    const active = switcher.checked && !switcher.nextSibling.classList.contains('inactive');
    this.form.querySelector(`#${targetId}`).style.display = active ? 'table-row' : 'none';
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const wrapper = document.getElementById('dynamic-prospects-information');
  if (wrapper) {
    wrapper.addEventListener('updated', () => {
      const formInstance = new DynamicProspectNotificationsForm(wrapper);
      formInstance.init();
    });
    wrapper.dispatchEvent(new CustomEvent('updated'));
  }
});
