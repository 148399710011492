class WeekdaysMultiListing {
  chooseListingForWeekdayNode(node){
    var listings_span = node.closest('span');
    var title = node.selectedOptions[0].text.trim();
    var id = node.value;
    if (id == 'all') {
      listings_span.querySelector('.selected_listing')?.remove();
    } else {
      if (listings_span.querySelectorAll('.selected_listing').length == 1) {
        if (listings_span.querySelector('.selected_listing input').value == 'all') {
          listings_span.querySelector('.selected_listing')?.remove();
        }
      }
    }
    var listing_exists = false;
    listings_span.querySelectorAll('.selected_listing input').forEach(function(input){
      if(input.value == id) { listing_exists = true; }
    });
    if(listing_exists) { return; }

    var selected_listing_html =
        ' <div class="selected_listing" title="Remove"><input type="hidden" value="'+id+'">'+
        title+
        '</div>';
    node.remove();
    listings_span.querySelector('.ts-wrapper').remove();
    listings_span.insertAdjacentHTML('beforeend', selected_listing_html);
    this.addListingsDropdownToWeekday(listings_span);
  }

  removeSelectedListingHandler(listing) {
    let listings = document.querySelectorAll('#update_weekdays_form .selected_listing');
    if (listing) {
      listings = [listing];
    }
    listings.forEach((el) => {
      el.addEventListener('click', (e) => {
        var listings_span = e.target.closest('span');
        e.target.remove(); //remove the listing
        if (listings_span) {
          listings_span.querySelector('select').remove();
          listings_span.querySelector('.ts-wrapper').remove();
          this.addListingsDropdownToWeekday(listings_span);
        }
      });
    });
  }

  addWeekdayShowingWindowToNode(node){
    var showwindow_html = document.querySelector('#show-window-listings-template').innerHTML;
    node.insertAdjacentHTML('beforeend', showwindow_html);
    document.dispatchEvent(new CustomEvent('html-updated', {detail: {container: '#update_weekdays_form'}}));
    const listings = node.querySelectorAll('.show_window_listings');
    this.addListingsDropdownToWeekday(listings[listings.length - 1]);
  }

  addListingsDropdownToWeekday(node){
    var listing_select_html = document.querySelector('.js-update-weekdays-form').dataset.addListingHtml;
    node.insertAdjacentHTML('beforeend', listing_select_html);

    const selectedListings = node.querySelectorAll('.selected_listing');

    this.removeSelectedListingHandler(selectedListings[selectedListings.length - 1]);
    const dropdown = node.querySelector('select.add_listing_dropdown');
    dropdown.addEventListener('change', (e) => {
      if(e.target.value != '') {
        this.chooseListingForWeekdayNode(e.target);
      }
    });
    document.dispatchEvent(new CustomEvent('sm-rich-ui-select:init', {detail: {el: node.querySelector('.add_listing_dropdown.js-sm-rich-ui-select')}}));
  }

  enableWeekdayEditMode(){
    LayoutUtils.show(document.querySelectorAll('#update_weekdays_form .edit_day_switch'));
    LayoutUtils.show(document.querySelectorAll('#update_weekdays_form .add_show_window'));
    LayoutUtils.hide(document.querySelectorAll('#update_weekdays_form .edit_day_txt'));
    LayoutUtils.hide(document.querySelectorAll('#edit-weekdays'));
    LayoutUtils.show(document.querySelectorAll('#edit-weekdays-submit'));
    LayoutUtils.show(document.querySelectorAll('#edit-weekdays-cancel'));
  }

  disableWeekdayEditMode(){
    LayoutUtils.hide(document.querySelectorAll('#update_weekdays_form .edit_day_switch'));
    LayoutUtils.hide(document.querySelectorAll('#update_weekdays_form .add_show_window'));
    LayoutUtils.show(document.querySelectorAll('#update_weekdays_form .edit_day_txt'));
    LayoutUtils.show(document.querySelectorAll('#edit-weekdays'));
    LayoutUtils.hide(document.querySelectorAll('#edit-weekdays-submit'));
    LayoutUtils.hide(document.querySelectorAll('#edit-weekdays-cancel'));
  }

  validateTimeRangesWeekdays() {
    var valid = true;
    var showWindows = document.querySelectorAll('#update_weekdays_form .edit_day_switch .show_window');

    showWindows.forEach(function(window) {
      var start = window.querySelector('.start_time_input');
      var endTime = window.querySelector('.end_time_input');

      if (!checkTimeRange(start.value, endTime.value)) {
        start.classList.add('invalid-format');
        endTime.classList.add('invalid-format');

        valid = false;
      }
    });

    return valid;
  }

  generateJsonPayloadWeekday() {
    var payload = [];
    var outsideBusinessHours = [];
    var days = document.querySelectorAll('#update_weekdays_form .edit_day_switch');
    days.forEach(function(day){
      var weekday_id = day.querySelector('input').value;
      var show_windows = day.querySelectorAll('.show_window');
      var show_window_payload = [];
      show_windows.forEach(function(sw){
        var start = sw.querySelector('.start_time_input').value;
        var end = sw.querySelector('.end_time_input').value;
        var listings = sw.querySelectorAll('.selected_listing input');
        var old_id = sw.querySelector('.old_id')?.value;
        var listing_ids = [];
        listings.forEach(function(l){
          listing_ids.push(l.value);
        });
        show_window_payload.push({'start' : start,
          'end' : end,
          'old_id' : old_id,
          'listing_ids' : listing_ids});

        outsideBusinessHours.push(isOutsideBusinessHours(start, end));
      });
      payload.push({'weekday_id': weekday_id,
        'show_windows': show_window_payload});
    });
    document.querySelector('input[name="json_payload_weekday"]').value = JSON.stringify(payload);

    return { outsideBusinessHours: (outsideBusinessHours.indexOf(true) > -1) };
  }

  editWeekdaysHandler() {
    const button = document.querySelector('#edit-weekdays');
    if (button) {
      button.replaceWith(button.cloneNode(true));
      document.querySelector('#edit-weekdays').addEventListener('click', () => {
        if(document.querySelector('#edit-single-days-submit').classList.contains('hidden')){
          this.enableWeekdayEditMode();
          return false;
        }
        else{
          alert('Please submit or cancel your current changes before editing this panel.');
          return true;
        }
      });
    }
  }

  submitWeekdaysHandler() {
    const button = document.querySelector('#edit-weekdays-submit');
    if (button) {
      button.replaceWith(button.cloneNode(true));
      document.querySelector('#edit-weekdays-submit').addEventListener('click', (e) => {
        e.preventDefault();
        this.disableWeekdayEditMode();

        document.querySelectorAll('#update_weekdays_form .invalid-format').forEach((el) => {
          el.classList.remove('invalid-format');
        });
        var valid = this.validateTimeRangesWeekdays();

        if (valid) {
          var payloadWeekday = this.generateJsonPayloadWeekday();
          LayoutUtils.hide(document.querySelector('#update_weekdays_form .calendar_time_error'));

          if (!payloadWeekday.outsideBusinessHours || confirm(outsideBusinessHoursAlertText)) {
            this.disableWeekdayEditMode();
            Rails.fire(document.querySelector('#update_weekdays_form'), 'submit');
          } else {
            this.enableWeekdayEditMode();
            return false;
          }
        } else {
          this.enableWeekdayEditMode();
          LayoutUtils.show(document.querySelector('#update_weekdays_form .calendar_time_error'));
        }

        return false;
      });
    }
  }

  cancelWeekdaysHandler() {
    const button = document.querySelector('#edit-weekdays-cancel');
    if (button) {
      button.replaceWith(button.cloneNode(true));
      document.querySelector('#edit-weekdays-cancel').addEventListener('click', () => {
        document.querySelector('#edit-weekdays-cancel')?.addEventListener('click', () => {
          LayoutUtils.hide(document.querySelector('#update_weekdays_form .calendar_time_error'));
          document.querySelectorAll('#update_weekdays_form .invalid-format').forEach((el) => {
            el.classList.remove('invalid-format');
          });
          this.disableWeekdayEditMode();
          return false;
        });
      });
    }
  }

  addShowWindow() {
    document.querySelectorAll('#update_weekdays_form .add_show_window').forEach((el) => {
      el.addEventListener('click', (e) => {
        var day = e.target.closest('.js-day-item');
        LayoutUtils.hide(day.querySelectorAll('.day_no_show_windows'));
        this.addWeekdayShowingWindowToNode(day.querySelector('.edit_day_switch'));
        const windows = day.querySelectorAll('.show_window');
        this.removeShowWindow(windows[windows.length - 1]);
      });
    });
  }

  removeShowWindow(wrapper) {
    let removeLinks = document.querySelectorAll('#update_weekdays_form .remove_show_window');
    if (wrapper) {
      removeLinks = wrapper.querySelectorAll('.remove_show_window');
    }
    removeLinks.forEach((el) => {
      el.addEventListener('click', function() {
        var day = el.closest('.js-day-item');
        el.closest('.show_window').remove();
        if(!day.querySelector('.show_window')){
          LayoutUtils.show(day.querySelectorAll('.day_no_show_windows'));
        }
      });
    });
  }

  bindEvents() {
    this.editWeekdaysHandler();
    this.submitWeekdaysHandler();

    this.cancelWeekdaysHandler();

    this.removeSelectedListingHandler();

    this.addShowWindow();
    this.removeShowWindow();

    document.querySelectorAll('input[type=text].not_available').forEach((el) => {
      el.addEventListener('click', function(e) {
        /* Select field contents */
        e.target.select();
      });
    });

    document.addEventListener('enable-weekday-edit-mode', () => {
      this.enableWeekdayEditMode();
    });
  }

  initListings() {
    document.querySelectorAll('#week-days .show_window_listings').forEach((el) => {
      this.addListingsDropdownToWeekday(el);
    });
  }

  init() {
    this.bindEvents();
    this.initListings();
  }
}

document.addEventListener('DOMContentLoaded', function() {
  if (document.querySelector('.js-update-weekdays-form')) {
    new WeekdaysMultiListing().init();

    document.addEventListener('html-updated', (e) => {
      if (e.detail?.container == '#week-days') {
        new WeekdaysMultiListing().init();
      }
    });
  }
});
